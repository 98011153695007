<template>
    <div>
        <div v-if="tempObject.id">
            <v-toolbar>
                <v-btn  v-if="isMobile==true"
                        fab small depressed
                        @click='$emit("close")'
                        >
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>

                <v-toolbar-title>           

                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn icon @click='$emit("update:editMod",!editMod)'  v-if="!editMod && tempObject.isAdmin==true"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn icon @click='deleteModal=true'  v-if="!editMod && tempObject.isAdmin==true" color="error"><v-icon>mdi-delete</v-icon></v-btn>
                <v-btn icon @click='$emit("update:editMod",!editMod)'  v-if="editMod && tempObject.isAdmin==true" color="error"><v-icon>mdi-cancel</v-icon></v-btn>
                <v-btn icon @click='objectSave()'   v-if="editMod && tempObject!=object && tempObject.isAdmin==true" color="success"><v-icon>mdi-content-save</v-icon></v-btn>
            </v-toolbar>

            <div :style='isMobile?"position: fixed; top:60px; right:10px; z-index:10;":"position: fixed; top:60px; right:70px; z-index:10;"'>
                <v-btn fab class="mx-1" @click='uploadSlideModal()' small v-if="tempObject.isAdmin==true" color="primary"><v-icon>mdi-upload</v-icon></v-btn>
                <v-btn fab class="mx-1" @click.prevent.stop="fileTemp=images[curSlide]; fileRenameModal=true" small  v-if="images.length>0 && tempObject.isAdmin==true" color="primary"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn fab class="mx-1" @click.prevent.stop="fileTemp=images[curSlide]; fileDeleteModal=true"  small v-if="images.length>0 && tempObject.isAdmin==true" color="error"><v-icon>mdi-delete</v-icon></v-btn>          
            </div>


            <v-carousel v-if="tempObject.id && images.length>0"
                        :continuous="true"
                        :cycle="true"
                        :show-arrows="false"
                        v-model="curSlide"
                        hide-delimiter-background

                        height="200"
                        >
                <v-carousel-item
                    style='cursor:pointer;'
                    @click="showFile(images[curSlide])"
                    v-for="(slide, i) in images"
                    :key="i"
                    :src="slide.src"
                    >
                </v-carousel-item>
            </v-carousel>
            <v-img v-if="tempObject.id && images.length==0" src="https://atgnn.ru/wp-content/uploads/2019/08/nophoto.jpg" height="200"/>
        </div>
        <v-list>
            <v-list-item>
                <v-list-item-avatar>
                    <v-img :src="getObjectIcon(object.state)"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                    <v-textarea dense hide-details="auto" class="pa-0" label="Название" v-if='editMod==true' v-model='tempObject.name' auto-grow :rows="1" row-height="20" :background-color="tempObject.name!=object.name?'amber lighten-4':''"></v-textarea><span v-else>{{object.name}}<v-btn v-if="tempObject.id" fab @click='$emit("showPath",object)' small color="primary"><v-icon>mdi-map-marker-path</v-icon></v-btn></span>
                </v-list-item-content>
                <v-list-item-action v-if="!tempObject.id">
                    <v-btn icon @click='$emit("close")'><v-icon color='error'>mdi-close</v-icon></v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
            <v-list-item style='min-height: 0px;' >
                <b class="mr-3">Пожарная часть:</b> <span v-if="!editMod && tempObject.station">{{tempObject.station.name}}</span>
                <v-select v-else
                          item-value="id"
                          item-text="name"
                          :items="stations"
                          v-model="tempObject.stationId"
                          label="Пожарная часть"
                          ></v-select>
            </v-list-item>
            <v-list-item style='min-height: 0px;'>
                <b class="mr-3">Исправность:</b> <span v-if="!editMod">{{tempObject.state==1?'Да' :"Нет"}}</span>
                <v-select v-else
                          item-value="id"
                          item-text="name"
                          :items="[{id:'0',name:'Нет'},{id:'1',name:'Да'}]"
                          v-model="tempObject.state"
                          label="Исправность"
                          ></v-select>
            </v-list-item>
            <v-list-item style='min-height: 0px;' class="py-1">
                <b class="mr-3">Адрес:</b>  <v-text-field height="20" class="pa-0" dense hide-details="auto" v-if='editMod==true' v-model='tempObject.address' :background-color="tempObject.phone!=object.phone?'amber lighten-4':''"></v-text-field><span v-else>{{object.address}}</span>
            </v-list-item>
            <v-list-item style='min-height: 0px;' class="py-1">
                <b class="mr-3">Описание:</b>  <v-list-item-content><v-textarea label="Описание" dense hide-details="auto" class="pa-0" v-if='editMod==true' v-model='tempObject.text' auto-grow :rows="1" row-height="20" :background-color="tempObject.text!=object.text?'amber lighten-4':''"></v-textarea><span v-else v-html='object.text'></span></v-list-item-content>
            </v-list-item>

        </v-list>
        <v-btn  @click='objectSave()'  class="ma-2" style="float:right" v-if="!tempObject.id" color="success">Сохранить<v-icon>mdi-content-save</v-icon></v-btn>

        <v-dialog v-model='uploadModal' max-width="400">
            <v-card>
                <v-card-title class="headline">Добавить изображение</v-card-title>
                <v-card-text>
                    <v-overlay
                        absolute
                        :value="uploading"
                        >
                    </v-overlay> 
                    <v-list-item-subtitle class="text-wrap">
                        Прикрепите документ и нажмите загрузить
                    </v-list-item-subtitle>
                    <div class="mt-4">
                        <v-file-input show-size v-model="newFile.file" label="Изображение" accept=".jpeg, .jpg, .png" v-if="uploadType=='waterImage'"></v-file-input>
                        <v-text-field v-model="newFile.name" label="Название"></v-text-field>
                    </div>
                    <v-btn color="primary" v-if="!uploading" @click="uploadSlide()">Загрузить</v-btn>
                    <v-progress-linear style="width:100%"
                                       v-if="uploading"
                                       v-model="uploadPercentage"
                                       height="25"
                                       >
                        <template v-slot:default="{ value }">
                            <strong>{{ Math.ceil(value) }}%</strong>
                        </template>
                    </v-progress-linear>
                </v-card-text>

            </v-card>

        </v-dialog>
        <v-dialog v-model="fileDeleteModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>После удаления восстановление документа невозможно.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="fileDeleteModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="fileDelete()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="fileRenameModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Переименовать</v-card-title>
                <v-card-text><v-text-field v-model="fileTemp.name" label="Введите новое название"></v-text-field></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="fileRenameModal = false">Отменить</v-btn>
                    <v-btn color="primary" text @click="fileRename()">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>После удаления восстановление объекта невозможно.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="deleteModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="objectDelete()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "Example",
        components: {

        },
        props: {
            docView: Object,
            editMod: Boolean,
            object: Object,
            waterSources: Array,
            stations: Array,
            isMobile: Boolean
        },
        watch: {
            'object'() {
                this.cloneObject()
                this.fetchData()
            }
        },
        data() {
            return {
                deleteModal: false,
                fileTemp: {},
                fileDeleteModal: false,
                fileRenameModal: false,
                fileView: null,
                uploadModal: false,
                curSlide: 0,
                uploading: false,
                uploadType: null,
                uploadPercentage: 0,
                images: [],
                newFile: {},
                tempObject: {}
            };
        },
        created() {
            this.cloneObject()
        },
        mounted() {
            this.fetchData()
        },
        methods: {
            fetchData() {
                this.$http.get('area/water-images?id=' + this.tempObject.id).then(response => {
                    this.images = response.data
                })

            },
            getObjectIcon(state) {
                return state == 1 ? '/PG.png' : '/PG_broken.png'
            },
            showFile(file) {
                this.$emit('update:docView', file)
            },
            fileDelete() {
                this.$http.get('stations/file-delete',
                        {
                            params: {
                                id: this.fileTemp.id
                            }
                        }).then(() => {
                    let index = this.images.findIndex(el => el.id == this.fileTemp.id)
                    this.images.splice(index, 1)
                    this.fileTemp = {}
                    this.fileDeleteModal = false
                })
            },
            fileRename() {
                this.$http.get('stations/file-rename',
                        {
                            params: {
                                id: this.fileTemp.id,
                                name: this.fileTemp.name,
                            }
                        }).then(() => {
                    let index = this.images.findIndex(el => el.id == this.fileTemp.id)
                    this.images[index].name = this.fileTemp.name
                    this.fileTemp = {}
                    this.fileRenameModal = false
                })

            },
            uploadSlideModal() {
                this.uploadType = 'waterImage'
                this.uploadModal = true
            },
            uploadSlide() {
                this.uploading = true
                var formData = new FormData()
                formData.append("name", this.newFile.name)
                formData.append("file", this.newFile.file)
                this.$http.post('stations/file-upload?objectId=' + this.tempObject.id + '&type=' + this.uploadType,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function (progressEvent) {
                                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                            }.bind(this)
                        }
                ).then(response => {
                    this.uploading = false
                    this.fileModal = false
                    this.newFile = {}
                    if (!this.object.images) {
                        this.$set(this.object, 'images', [])
                        this.$set(this.tempObject, 'images', [])
                    }
                    this.images.push(response.data)
                    this.uploadModal = false
                }).catch(e => {
                    this.uploading = false
                    alert(e.response.data)
                })
            },
            objectDelete() {
                this.$http.post('stations/water-delete?id=' + this.tempObject.id).then(() => {
                    let key = this.waterSources.findIndex(el => el.id == this.tempObject.id)
                    this.waterSources.splice(key, 1)
                    this.deleteModal = false
                    this.$emit("close")
                })
            },
            objectSave() {
                if (this.tempObject.id) {
                    this.$http.post('stations/water-update?id=' + this.tempObject.id,
                            this.tempObject).then(() => {
                        this.$emit('update:object', this.tempObject)
                        let key = this.waterSources.findIndex(el => el.id == this.tempObject.id)
                        this.$set(this.waterSources, key, this.tempObject)
                        this.$emit('update:editMod', false)
                    })
                } else {
                    this.$http.post('stations/water-create',
                            this.tempObject).then(response => {
                        this.waterSources.push(response.data)
                        this.$emit('update:editMod', false)
                        this.$emit('close')
                    })
                }


            },
            cloneObject() {
                this.tempObject = JSON.parse(JSON.stringify(this.object))
            }
        }
    };
</script>
