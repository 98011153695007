<template>
    <div>
        <div v-if="tempObject.id">
                    <v-toolbar>
                <v-btn  v-if="isMobile==true"
                        fab small depressed
                        @click='$emit("close")'
                        >
                    <v-icon>mdi-arrow-left</v-icon>
                </v-btn>

                <v-toolbar-title>           

                </v-toolbar-title>

                <v-spacer></v-spacer>
                
            </v-toolbar>
            <div :style='isMobile?"position: fixed; top:60px; right:10px; z-index:10;":"position: fixed; top:60px; right:70px; z-index:10;"'>
        <v-btn fab class="mx-1" @click='uploadSlideModal()' small v-if="tempObject.isAdmin==true" color="primary"><v-icon>mdi-upload</v-icon></v-btn>
        <v-btn fab class="mx-1" @click.prevent.stop="fileTemp=tempObject.images[curSlide]; fileRenameModal=true" small v-if="tempObject.images.length>0 && tempObject.isAdmin==true" color="primary"><v-icon>mdi-pencil</v-icon></v-btn>
        <v-btn fab class="mx-1" @click.prevent.stop="fileTemp=tempObject.images[curSlide]; fileDeleteModal=true"  small v-if="tempObject.images.length>0 && tempObject.isAdmin==true" color="error"><v-icon>mdi-delete</v-icon></v-btn>
            </div>
        <v-carousel v-if="tempObject.id && tempObject.images.length>0"
                    :continuous="true"
                    :cycle="true"
                    :show-arrows="false"
                    v-model="curSlide"
                    hide-delimiter-background
                    
                    height="200"
                    >
            <v-carousel-item
                style='cursor:pointer;'
                @click="showFile(tempObject.images[curSlide])"
                v-for="(slide, i) in tempObject.images"
                :key="i"
                :src="slide.src"
                >
            </v-carousel-item>
        </v-carousel>
        <v-img v-if="tempObject.id && tempObject.images.length==0" src="https://atgnn.ru/wp-content/uploads/2019/08/nophoto.jpg" height="200"/>
        </div>
        <v-list>
            <v-list-item>
                <v-list-item-avatar>
                    <v-icon>mdi-home</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    {{object.name}}
                </v-list-item-content>
                <v-list-item-action v-if="!object.id">
                    <v-btn icon @click='$emit("close")'><v-icon color='error'>mdi-close</v-icon></v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list>
            <v-list-item style='min-height: 0px;'>
                <b class="mr-3">Адрес:</b>  {{object.address}}
            </v-list-item>
            <v-list-item style='min-height: 0px;' class="py-1">
                <b class="mr-3">Тип:</b> <span v-if='object.type=="SPSCH"'>СПСЧ</span><span v-else>ПЧ</span>
            </v-list-item>
            <v-list-item style='min-height: 0px;' class="py-1">
                <v-btn @click="borderDialog=true" v-if='object.isAdmin==true'>Указать район выезда</v-btn>
            </v-list-item>
        </v-list>
        <v-dialog v-model='uploadModal' max-width="400">
            <v-card>
                <v-card-title class="headline">Добавить изображение</v-card-title>
                <v-card-text>
                    <v-overlay
                        absolute
                        :value="uploading"
                        >
                    </v-overlay> 
                    <v-list-item-subtitle class="text-wrap">
                        Прикрепите документ и нажмите загрузить
                    </v-list-item-subtitle>
                    <div class="mt-4">
                        <v-file-input show-size v-model="newFile.file" label="Изображение" accept=".jpeg, .jpg, .png" v-if="uploadType=='groupImage'"></v-file-input>
                        <v-text-field v-model="newFile.name" label="Название"></v-text-field>
                    </div>
                    <v-btn color="primary" v-if="!uploading" @click="uploadSlide()">Загрузить</v-btn>
                    <v-progress-linear style="width:100%"
                                       v-if="uploading"
                                       v-model="uploadPercentage"
                                       height="25"
                                       >
                        <template v-slot:default="{ value }">
                            <strong>{{ Math.ceil(value) }}%</strong>
                        </template>
                    </v-progress-linear>
                </v-card-text>

            </v-card>

        </v-dialog>
        <v-dialog v-model="fileDeleteModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>После удаления восстановление документа невозможно.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="fileDeleteModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="fileDelete()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="fileRenameModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Переименовать</v-card-title>
                <v-card-text><v-text-field v-model="fileTemp.name" label="Введите новое название"></v-text-field></v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="fileRenameModal = false">Отменить</v-btn>
                    <v-btn color="primary" text @click="fileRename()">Сохранить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
                        <v-dialog v-model="deleteModal" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>После удаления восстановление объекта невозможно.</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="deleteModal = false">Отменить</v-btn>
                    <v-btn color="error" text @click="objectDelete()">Удалить</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
                                <v-dialog v-model="borderDialog" persistent max-width="290">
            <v-card>
                <v-card-title class="headline">Вы уверены?</v-card-title>
                <v-card-text>Предыдущий район выезда будет удалён</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="borderDialog=false">Отменить</v-btn>
                    <v-btn color="error" text @click="borderAdd()">Указать</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        name: "Example",
        components: {

        },
        props: {
            isMobile: Boolean,
            docView: Object,
            editMod: Boolean,
            object: Object
        },
        watch: {
            'object'() {
                this.cloneObject()
            }
        },
        data() {
            return {
                borderDialog:false,
                deleteModal:false,
                fileTemp: {},
                fileDeleteModal: false,
                fileRenameModal: false,
                fileView: null,
                uploadModal: false,
                curSlide: 0,
                uploading: false,
                uploadType: null,
                uploadPercentage: 0,
                newFile: {},
                tempObject: {}
            };
        },
        created() {
            this.cloneObject()
        },
        methods: {
            borderAdd(){
                this.$emit('borderAdd')
                alert('Укажите на карте район выезда, нажимая на точки где он проходит.')
                this.borderDialog=false
            },
            showFile(file) {
                this.$emit('update:docView', file)
            },
            fileDelete() {
                this.$http.get('stations/file-delete',
                        {
                            params: {
                                id: this.fileTemp.id
                            }
                        }).then(() => {
                        let index = this.tempObject.images.findIndex(el => el.id == this.fileTemp.id)
                        this.tempObject.images.splice(index, 1)
                        this.object.images.splice(index, 1)
                    this.fileTemp = {}
                    this.fileDeleteModal = false
                })
            },
            fileRename() {
                this.$http.get('stations/file-rename',
                        {
                            params: {
                                id: this.fileTemp.id,
                                name: this.fileTemp.name,
                            }
                        }).then(() => {
                        let index = this.tempObject.images.findIndex(el => el.id == this.fileTemp.id)
                        this.tempObject.images[index].name=this.fileTemp.name
                        this.object.images[index].name=this.fileTemp.name
                    this.fileTemp = {}
                    this.fileRenameModal = false
                })

            },
            uploadSlideModal() {
                this.uploadType = 'groupImage'
                this.uploadModal = true
            },
            uploadSlide() {
                this.uploading = true
                var formData = new FormData()
                formData.append("name", this.newFile.name)
                formData.append("file", this.newFile.file)
                this.$http.post('stations/file-upload?objectId=' + this.tempObject.id + '&type=' + this.uploadType,
                        formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            onUploadProgress: function (progressEvent) {
                                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
                            }.bind(this)
                        }
                ).then(response => {
                    this.uploading = false
                    this.fileModal = false
                    this.newFile = {}
                    if (!this.object.images) {
                        this.$set(this.object, 'images', [])
                        this.$set(this.tempObject, 'images', [])
                    }
                    this.tempObject.images.push(response.data)
                    this.object.images.push(response.data)
                    this.uploadModal = false
                }).catch(e => {
                    this.uploading = false
                    alert(e.response.data)
                })
            },
            cloneObject() {
                this.tempObject = JSON.parse(JSON.stringify(this.object))
            }
        }
    };
</script>
